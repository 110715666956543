











































































import Component from 'vue-class-component';
import Vue from 'vue';
import SvgIcon from '@/components/general/SvgIcon.vue';
import {articles,articlesCategories,maxPerPage} from '@/collections/articles'
import { Watch } from 'vue-property-decorator';
import BannerTitled from '@/components/general/BannerTitled.vue';
import firebase from "firebase";

@Component({
    components:{
        SvgIcon,
        BannerTitled
    }
})
export default class Articles extends Vue { 
    tabs= 0;
    page =1;
    articles : Record<any,any>= []
    articlesCategories : Record<any,any>=[]
    currentFirstItem = 0;
    storage = firebase.storage();

    created(){
        this.articles = articles
        this.articlesCategories = articlesCategories;
    }
    @Watch('tabs')
      changeTab(){
        this.page = 1
      }
    @Watch('page')
        changePage(){
            this.currentFirstItem = (this.page - 1) * maxPerPage;
        }

    get getCollectionPaginated() : any {      
        return  this.articlesCategories[this.tabs].collection.slice(this.currentFirstItem,this.currentFirstItem + maxPerPage)
    } 

    get getPaginationLength() : number{ 
       return Math.ceil(this.articlesCategories[this.tabs].collection.length / maxPerPage);
    }

    downloadPdf(id : number) {
        const resumeRef = this.storage.ref(`articulos/${id}.pdf`);
        resumeRef.getDownloadURL().then((url) => {
                var link = document.createElement('a');
                link.href = url;
                link.target= "_blank"
                link.dispatchEvent(new MouseEvent('click'));
        }).catch(() => {
        });
    }
    isSmallDevice(){
        if (this.$vuetify.breakpoint.name == 'xs'){
            return true
        }
        else false
    }
}
